let SelectVehiculo = $('#descripcion');
let SelectMotor = $('#motor');
let Form = $('#busqueda-rep');
let Buscando = `
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
      <h2 class="title-section"><i class="fa fa-spin fa-refresh"></i> Buscando</h2>
    </div>`;
let Result = $('.result-data');

export const GetVehiculos = (callback) => {
  console.log('Repuestos vehiculos');

  $.ajax({
    url:     '/actions/repuestos_vehiculos',
    method:  'POST',
    data:    {},
    beforeSend() {
      SelectVehiculo.html('<option disabled selected>Cargando</option>');
    },
    success: function (res) {
      let rend = '<option selected value="">Todos los vehiculos</option>';
      for (let i = 0; i < res.length; i++) {
        rend += `<option value="${res[i]['descrip']}">${res[i]['descrip']}</option>`;
      }
      SelectVehiculo.html(rend);

      return callback
    },
    error:   function (res) {
      console.log(res.responseText);
    },
  });
};

export const GetMotor = (descripcion) => {
  $.ajax({
    url:        '/actions/repuestos_vehiculos_motores',
    method:     'POST',
    data:       {
      descripcion: descripcion,
    },
    beforeSend: function () {
      SelectMotor.html('<option disabled selected>Cargando...</option>')
    },
    success:    function (res) {
      console.table(res);
      let rend = '<option value="">Todos los motores</option>';
      for (let i = 0; i < res.length; i++) {
        rend += `<option value="${res[i]['motor']}">${res[i]['motor']}</option>`;
      }
      SelectMotor.html(rend);
      GetCarroceria(SelectVehiculo.val(), res[0]['motor']);
    },
    error:      function (res) {
      console.log(res.responseText);
    },
  })
};

export const GetCarroceria = (descripcion, motor) => {
  let SelectCarroceria = $('#carroceria');

  $.ajax({
    url:        '/actions/repuestos_vehiculos_carroceria',
    method:     'POST',
    data:       {
      descripcion: descripcion,
      motor:       motor,
    },
    beforeSend: function () {
      SelectCarroceria.html('<option disabled selected>Cargando...</option>')
    },
    success:    function (res) {
      console.table(res);
      let rend = '<option value="">Todas las carrocerias</option>';
      for (let i = 0; i < res.length; i++) {
        rend += `<option value="${res[i]['carroceria']}">${res[i]['carroceria']}</option>`;
      }
      SelectCarroceria.html(rend);
    },
    error:      function (res) {
      console.log(res.responseText);
    },
  })
};

export const BuscarRepuestos = () => {

  Form.submit(function (e) {
    e.preventDefault();
    let This = $(this);
    let Data = This.serializeArray();
    console.log(Data);
    $.ajax({
      url:        This.attr('action'),
      method:     'POST',
      data:       Data,
      beforeSend: function () {
        Result.html(Buscando);
      },
      success:    function (res) {
        console.log(res);
        Result.html(res);
      },
      error:      function (res) {
        Result.html(res.responseText);
      },
    })
  })
};

export const SendBusqueda = (offset) => {
  let url = Form.attr('action');

  if (offset !== 0) {
    url = `/actions/busqueda_repuestos/${offset}`;

    if (offset < 0) {
      url = `/actions/busqueda_repuestos/0`;
    }
  }

  $.ajax({
    url:        url,
    method:     'POST',
    data:       Form.serializeArray(),
    beforeSend: function () {
      Result.html(Buscando);
    },
    success:    function (res) {
      console.log(res);
      Result.html(res);
    },
    error:      function (res) {
      Result.html(res.responseText);
    },
  })
};

