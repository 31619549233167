export default class Carrito {
  /**
   * Obtener el carrito
   * @param session_code
   */
  static getCarritoTienda(session_code) {
    let Cart = $('.module-cart');

    $.ajax({
      url:        `/actions/get_carrito`,
      data:       null,
      method:     'POST',
      beforeSend: function () {
        $('.total-cart').html('<i class="fa fa-spin fa-spinner"></i>');
      },
      success:    function (res) {
        console.log(res);
        let Cantidad = res.count;

        Cart.find('span.cart-label').text(Cantidad);
        Cart.find('.total-price').find('h5').text('$' + res.total);
        Cart.find('.total-desc').find('h5').find('span').text(Cantidad + ' producto');

        setTimeout(function () {
          $('.total-cart').html(res.total)
        }, 400);

      },
      error:      function (res) {
        console.log(res);
      },
    })
  }

  static addToCart(This) {
    let Actual = This.html();
    let Aviso = $('.aviso');

    let Data = {
      tienda:              1,
      name:                This.data('name'),
      session_code:        This.data('session'),
      ip:                  This.data('ip'),
      product_id:          This.data('codigo'),
      product_category:    This.data('product_category'),
      product_subcategory: This.data('product_subcategory'),
      product_type:        This.data('type'),
      cantidad:            1,
      precio_unitario:     This.data('precio'),
      precio_total:        This.data('precio'),
      alto:                This.data('alto'),
      ancho:               This.data('ancho'),
      profundidad:         This.data('profundidad'),
      peso:                This.data('peso'),
      marca:               This.data('marca'),
    };

    $.ajax({
      url:     This.attr('href'),
      method:  'POST',
      data:    Data,
      beforeSend() {
        This.attr('disabled', true).html('<i class="fa fa-spin fa-spinner"></i>');
      },
      success: function (res) {
        This.attr('disabled', false).html(Actual);
        console.log(res);

        //Obtener el carrito
        if (res.result === 'actualizado' || res.result === 'ingresado') {
          console.log(Data.session_code);
          Carrito.getCarritoTienda(Data.session_code);

          Aviso.css({
            'display': 'block',
          });

          Aviso.find('span.name').html(Data.name);
          setTimeout(function () {
            Aviso.css('display', 'none');
          }, 1500);
        } else {
          alert(res.result);
        }

      },
      error:   function (res) {
        This.attr('disabled', false).html(Actual);
        console.log(res);
      },
    });
  }
}