import Carrito from './Carrito';
import {GetVehiculos, GetMotor, GetCarroceria, BuscarRepuestos, SendBusqueda} from './Busquedas';
import Counter from './Counter';

/**
 * Leer un cookie
 * @param name
 * @returns {string|null}
 */
function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

/**
 * Sumar
 * @returns {number}
 */
function sumar() {
  let Seguro = 0;
  $('input[name="garantia"]').each(function () {
    Seguro = $('input[name="garantia"]:checked').val();
  });

  let val = $('span.price').text().replace('.', '');
  val = val.replace('.', '');

  return parseInt(val) +
    parseInt($('span.price_flete').text()) +
    parseInt($('span.price_formulario').text()) +
    parseInt(Seguro);
}

/**
 * Format number
 * @param number
 * @returns {string}
 */
function number_format(number) {
  let Num = parseFloat(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&.');
  return Num.substr(0, Num.length - 3)
}

/**
 * Check financiacion
 * @param data
 */
function checkFinan(data) {
  let ResData = $('.res-data');
  ResData.removeClass('alert');
  ResData.removeClass('alert-danger');
  ResData.html('');

  $.ajax({
    url: '/actions/check_financiacion',
    method: 'POST',
    data: data,
    beforeSend: function () {

    },
    success: function (res) {
      if (res !== '') {
        ResData.html(`<h4>${res}</h4>`);
        ResData.addClass('alert');
        ResData.addClass('alert-danger');
      }
    },
    error: function (res) {

    },
  })
}

/**
 * Cambiar valores
 * @param tasa
 * @param valor
 * @param position
 */
function changeVals(tasa, valor, position, tma, seq) {
  $.ajax({
    url: '/actions/get_financiacion_vals',
    method: 'POST',
    data: {
      valor: valor,
      position: position,
      tma: tma,
      seq: seq,
    },
    beforeSend: function () {

    },
    success: function (res) {
      console.log(res);
      let final = res;

      let Span = $('.item-body[data-tasa="' + tasa + '"]');
      Span.find('span.primer-cuota').html('$' + number_format(final.valor_primera_cuota, 2, ',', '.'));
      Span.find('span.cuota-promedio').html('$' + number_format(final.valor_cuota_promedio, 2, ',', '.'));
      Span.find('span.cuota-final').html('$' + number_format(final.valor_cuota_final, 2, ',', '.'));

      let FormF = $('form[data-tasa="' + tasa + '"]');
      FormF.find('#valor_primera_cuota').val(number_format(final.valor_primera_cuota, 2, ',', '.'));
      FormF.find('#valor_cuota_promedio').val(number_format(final.valor_cuota_promedio, 2, ',', '.'));
      FormF.find('#valor_cuota_final').val(number_format(final.valor_cuota_final, 2, ',', '.'));

    },
    error: function (res) {
      console.log(res.responseText);
    },

  });
}

$(function () {
  //Obtener el carrito
  Carrito.getCarritoTienda('');
  Counter.create('counter-promo');

  $(document).on('click', '.add-button', function (e) {
    e.preventDefault();
    let This = $(this);
    Carrito.addToCart(This);
  });

  $('.menu-open').click(function (e) {
    e.preventDefault();
    $('ul.nav-menu').addClass('active');
  });
  /**
   * Cambiar la cantidad
   */
  $(document).on('change', '.change-cant', function () {
    setTimeout(function () {
      Carrito.getCarritoTienda('');
    }, 300);
  });

  /**
   * Añadir al carrito reparacion
   */
  $(document).on('click', '.add-cart-rep', function (e) {
    e.preventDefault();
    let This = $(this);
    Carrito.addToCart(This);
  });

  /**
   * Añadir al carrito servicio mantenimiento
   */
  $(document).on('click', '.add-cart-serv', function (e) {
    e.preventDefault();
    let This = $(this);
    Carrito.addToCart(This);
  });

  /**
   * Ver asociaciones
   */
  $(document).on('click', '.show-asocs', function (e) {
    e.preventDefault();
    let This = $(this);
    let Code = This.data('code');
    console.log(Code);
    $(`.asocs[data-code="${Code}"]`).css('display', 'block');
  });

  /**
   * Cerrar las asociaciones
   * de autos de los cero
   */
  $(document).on('click', '.close-asocs', function (e) {
    e.preventDefault();
    let This = $(this);
    let Code = This.data('code');

    console.log(Code);
    $(`.asocs[data-code="${Code}"]`).css('display', 'none');
  });

  /**
   * Enviar consulta de plan o de 0km
   */
  $(document).on('click', '.btn-send-consulta', function () {
    let This = $(this);
    let Code = This.data('id');
    let Form = $(`.form-send[data-id="${Code}"]`);
    console.log(Form.length);

    Form.submit(function (e) {
      e.preventDefault();
      $.ajax({
        url: Form.attr('action'),
        data: Form.serializeArray(),
        method: 'POST',
        success: function (res) {
          console.log(res);
          if (res === '(3.2) - El servicio de carga de datos se ejecuto correctamente.' || res === 'El servicio de carga de datos se ejecuto correctamente.') {
            Form.find('input[type="text"]').val('');
            Form.find('textarea').val('');
            Form.find('input[type="email"]').val('');
            alert('Consulta enviada correctamente');
            $(`#consulta-${Code}`).modal('hide');
          }
        },
        error: function (res) {
          alert(res.responseText);
        },
      });
    });
  });

  // ===================================================
  // ========== Form de registro =======================
  // ===================================================
  let FormRegistro = $('.form-registro');
  let SituacionFiscal = FormRegistro.find('#situacion_fiscal');

  SituacionFiscal.find('option:first').attr('disabled', 'true');
  SituacionFiscal.on('change', function () {
    let This = $(this);


    if (This.val() == 'responsable_inscripto') {
      // FormRegistro.find('#cuit').css('display', 'block');
      // FormRegistro.find('#dni').css('display', 'none');
      // FormRegistro.find('#cuit').attr('required', true);
      FormRegistro.find('#dni').attr('placeholder', 'CUIT');
    } else {
      // FormRegistro.find('#cuit').css('display', 'none');
      // FormRegistro.find('#dni').css('display', 'block');
      // FormRegistro.find('#cuit').removeAttr('required');
      FormRegistro.find('#dni').attr('placeholder', 'DNI');
    }

  });

  // ===================================================
  // ========== Busqueda selects =======================
  // ===================================================
  let SelectVehiculo = $('#descripcion');
  let SelectMotor = $('#motor');
  let Form = $('#busqueda-rep');

  GetVehiculos();

  SelectVehiculo.change(function () {
    let This = $(this);
    GetMotor(This.val());
  });

  SelectMotor.change(function () {
    let This = $(this);
    GetCarroceria(SelectVehiculo.val(), This.val());
  });

  //Buscar repuestos
  BuscarRepuestos();

  /**
   * Filtrar resultados por categoria
   */
  $(document).on('click', '.filter-result', function (e) {
    e.preventDefault();
    let This = $(this);
    Form.find('#categoria').val(This.data('categoria'));
    setTimeout(function () {
      SendBusqueda(0);
    }, 200)
  });

  $(document).on('click', '.filter-result-sub', function (e) {
    e.preventDefault();
    let This = $(this);
    Form.find('#subcategoria').val(This.data('subcategoria'));

    setTimeout(function () {
      SendBusqueda(0);
    }, 200)
  });

  $(document).on('click', '.search-button-rep', function () {
    Form.find('#categoria').val('');
    Form.find('#subcategoria').val('');
  });

  // ===================================================
  // ========== Detalle del repuesto ===================
  // ===================================================
  let DetailRep = $('.detail-rep');
  let DetailRepBack = $('.detail-rep-back');
  let CloseDetail = $('.close-detail');

  $(document).on('click', '.btn-detail', function (e) {
    e.preventDefault();
    let This = $(this);
    let Href = This.attr('href');

    DetailRep.css({'display': 'block'});
    DetailRepBack.css({'display': 'block'});

    $.ajax(Href, {
      method: 'GET',
      beforeSend: function () {
        DetailRep.html('<h2><i class="fa fa-spin fa-spinner"></i> Cargando</h2>');
      },
      success: function (res) {
        DetailRep.html(res);
        setTimeout(function () {
          $('.gal-repuesto').slick({
            autoPlay: true,
            autoplay: true,
            arrows: true,
            centerMode: false,
            prevArrow: $('.left-a'),
            nextArrow: $('.rigth-a'),
          });
        }, 300);
      },

      error: function (res) {
        alert(res.responseText);
      },
    });
  });

  /**
   * Cerrar detalle del repuesto
   */
  DetailRepBack.on('click', function () {
    DetailRep.html('');
    DetailRep.css('display', 'none');
    DetailRepBack.css('display', 'none');
  });

  $(document).on('click', '.close-detail', function () {
    DetailRep.html('');
    DetailRep.css('display', 'none');
    DetailRepBack.css('display', 'none');
  });


  $(document).on('click', '.load-reps-btn', function (e) {
    e.preventDefault();
    let This = $(this);
    let Actual = This.html();
    let Load = $('.load-reps');

    $('.load-reps-btn').each(function () {
      $(this).removeClass('active');
      $(this).attr('disabled', false);
    });

    $.ajax(
      '/actions/load_repuestos',
      {
        method: 'POST',
        data: {
          link: This.data('link'),
        },
        beforeSend: function () {
          This.html('<i class="fa fa-spin fa-refresh"></i>').attr('disabled', true);
        },
        success: function (res) {
          $(`.load-reps-btn[data-index="${This.data('index')}"]`)
            .addClass('active')
            .html(Actual)
            .attr('disabled', true);
          Load.html(res);
        },
        error: function (res) {
          This.html(Actual).attr('disabled', false);
          Load.html(res);
        },
      },
    )
  })

  $(document).on('click', '.paginator-result-btn', function (e) {
    e.preventDefault();
    let This = $(this);
    SendBusqueda(This.data('offset'));
  });


  let url = document.location.toString();
  if (url.match('#')) {
    $('.nav-tabs a[href="#' + url.split('#')[1] + '"]').tab('show');
  }

  // Change hash for page-reload
  $('.nav-tabs a').on('shown.bs.tab', function (e) {
    e.preventDefault();
    window.location.hash = e.target.hash;

    setTimeout(function () {
      $('body').scrollTop();
    }, 600);

  });

  // Obtener las tasas segun valor expresado
  $('.valor_fin').on('keyup keypress change', function () {
    let This = $(this);
    changeVals(This.data('tasa'), This.val(), This.data('position'), This.data('tma'), This.data('seq'));
  });

  // Mostrar el bloque de financiación
  $(document).on('focus', '.valor_fin', function (e) {
    let This = $(this);
    $(`.item-body[data-iden="${This.data("target")}"]`).show();
    $(`.item-body:not([data-iden="${This.data("target")}"])`).hide();
    $(`.item-financiacion[data-iden="${This.data("target")}"]`).addClass('active');
    $(`.item-financiacion:not([data-iden="${This.data("target")}"])`).removeClass('active');
  })

  // Boton sin financiación
  $(document).on('click', '.btn-no-finan', function (e) {
    e.preventDefault();
    let This = $(this);
    let Target = This.data('target');
    $(`#${Target}`).submit();
  });

  // Cálculos para temas de garantía
  $(document).on('change', 'input[name="garantia"]', function () {
    let This = $(this);
    let Total = $('span.total');
    sumar();
    Total.text(sumar());

    $.post('/actions/save_ford_protect', {valor: This.val()}, function (res) {
      console.log(res);
    });
  })

  // Boton de simulación
  $(document).on('click', '.simular', function (e) {
    e.preventDefault();
    let This = $(this);
    let Simular = $('#simular');
    let Destino = Simular.find('.modal-body');
    Simular.modal('show');

    $.ajax(`/financiacion_modal/${This.data('tma')}/${This.data('seq')}`, {
      method: 'GET',
      data: {},
      beforeSend: function () {
        Destino.html('<h3><i class="fa fa-spin fa-spinner"></i> Cargando</h3>');
      },
      success: function (res) {
        Destino.html(res);
      },
      error: function (res) {
        Destino.html(res.responseText);
      },
    });
  });


  $('#form-compra-cero').find('.form-control#codigo_postal').on('keyup keypress change', function (e) {
    let This = $(this);
    let Btn = $('.btn-confirm-cero');
    let Container = $('.btn-confirm-cero-container');

    if (This.val() && This.val().length > 2) {
      $.ajax({
        url: `/actions/check-codigo/${This.val()}`,
        method: 'POST',
        beforeSend: function () {

        },
        success: function (res) {
          if (!res.encontrado) {
            Btn.hide();
            let mensaje = '(Código postal no permitido para la venta)';
            Container.find('span').html(mensaje);
            $('span.msg[data-id="codigo_postal"]').text(mensaje).css('color', 'red');
          } else {
            Btn.show();
            Container.find('span').html('')
            $('span.msg[data-id="codigo_postal"]').text('');
          }

        },
        error: function (res) {
          console.log(res);
        },
      })
    } else {
      Btn.show();
      Container.find('span').html('')
      $('span.msg[data-id="codigo_postal"]').text('');
    }

    if (!This.val()) {
      Btn.show();
      Container.find('span').html('')
      $('span.msg[data-id="codigo_postal"]').text('');
    }
  })
});
