export default class Counter {
  static create(destination_id) {
    // Set the date we're counting down to
    let countDownDate = new Date("Nov 7, 2019 00:00:00").getTime();

    // Update the count down every 1 second
    let x = setInterval(function () {

      // Get today's date and time
      let now = new Date().getTime();

      // Find the distance between now and the count down date
      let distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Display the result in the element with id="demo"
      document.getElementById(destination_id).innerHTML =
         `<ul class="counter-time">
            <li>${days} <span>dias</span></li>
            <li>${hours} <span>horas</span></li>
            <li>${minutes} <span>minutos</span></li>
            <li>${seconds} <span>segundos</span></li>
          </ul>
          `;
         //days + "d " + hours + "h " + minutes + "m " + seconds + "s ";

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        document.getElementById("counter-container").innerHTML = "";
      }
    }, 1000);
  }
}